import axios from 'axios';

import { isLogged, logout, getAccessToken } from './auth';

let instance = null;

export function getInstance() {
  if (instance) {
    return instance;
  }

  const isLoggedIn = isLogged();

  instance = axios.create({
    timeout: 100000,
    headers: {
      Authorization: isLoggedIn ? `Bearer ${getAccessToken()}` : '',
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        logout();
      }

      return Promise.reject(error);
    }
  );

  return instance;
}

export default ['request', 'get', 'post', 'put', 'patch', 'delete'].reduce(
  (http, method) => ({
    ...http,
    [method]: (...args) => getInstance()[method](...args),
  }),
  {}
);
