import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import SEO from '../../components/SEO';
import { MainLayout } from '../../components/Layout';
import { TermAndPolicy } from '../../components/Pricing';
import * as paymentService from '../../apis/payment';
import {
  getParsedProducts,
  getSelectedPaymentMethod,
  saveParsedProducts,
  saveSelectedPaymentMethod,
} from '../../utils/cart';
import { isBrowser } from '../../utils/auth';

/**
 * @return {null}
 */
function Page({ location }) {
  const [agree, setAgree] = useState((location.state && location.state.agree) || false);
  const [products, setProducts] = useState(location.state && location.state.parsedProducts);
  const [paymentMethod, setPaymentMethod] = useState(location.state && location.state.selectedPaymentMethod);

  useEffect(() => {
    if (location.state.afterLogin) {
      setProducts(getParsedProducts());
      setPaymentMethod(getSelectedPaymentMethod());
      setAgree(true);
    } else {
      saveParsedProducts(location.state.parsedProducts);
      saveSelectedPaymentMethod(location.state.selectedPaymentMethod);
    }

    if (isBrowser() && window.innerWidth > 768) {
      navigate('/pricing/');
    }
  }, []);

  if (!location.state) {
    if (isBrowser()) navigate('/pricing/');
    return null;
  }

  return (
    <MainLayout>
      <SEO title="Pricing" keywords={[`dolonline`, `dolenglish`, `pricing`, 'ielts', 'gmat', 'gre']} />
      <TermAndPolicy
        agree={agree}
        onAgree={e => {
          setAgree(e.target.checked);
        }}
        onContinue={() => {
          paymentService
            .purchase(products, paymentMethod.code)
            .then(({ data: { onepay } }) => {
              window.open(onepay.paymentRequestUrl, '_blank');
            })
            .catch(err => {
              if (err.response.status === 401) {
                navigate('/login/');
              }
            });
        }}
      />
    </MainLayout>
  );
}

Page.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Page;
