import axios from 'axios';
import publicIP from 'public-ip';

import * as BaseURL from './baseURL';
import http from '../utils/http';

const productsUri = `${BaseURL.PAYMENT}/products`;
const paymentUri = `${BaseURL.PAYMENT}/payment`;

export const getProducts = () => axios.get(productsUri);

export const checkout = products => axios.post(`${productsUri}/checkout`, products);

export const getPaymentMethods = () => axios.get(`${paymentUri}/methods`);

export async function purchase(products, paymentMethodCode) {
  const ip = await publicIP.v4();
  return http.post(`${BaseURL.PAYMENT}/payment/purchase`, null, {
    params: {
      payment_method_code: paymentMethodCode,
    },
    data: {
      againLink: BaseURL.PRICING,
      ipAddress: ip,
      products,
    },
  });
}

export const ipnOnepay = payload => http.post(`${paymentUri}/onepay/ipn`, payload);

export const orderHistory = (page, size) =>
  http.get(`${paymentUri}/orders/history`, {
    params: {
      page,
      size,
    },
  });

export const orderHistoryDetail = orderId => http.get(`${paymentUri}/orders/${orderId}`);
